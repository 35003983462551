<template lang="pug">
v-card.page.page--portfolio(:class="{ ready: pageReady }" flat color="maintext--text")
  v-card-title.max-widthed
    h1
      v-icon(x-large) i-apps
      v-slide-x-reverse-transition
        div(v-show="pageReady") {{ texts.pageTitle }}

  v-card-text
    p.max-widthed.intro(v-html="texts.firstText")
    vueper-slides(
      autoplay
      bullets-outside
      slide-image-inside
      :breakpoints="slideshowBreakpoints"
      slide-content-outside="bottom"
      slide-content-outside-class="max-widthed")
      v-icon(slot="pause" color="primary" size="28") i-pause
      vueper-slide(
        v-for="slide in texts.projects"
        :key="slide.id"
        :image="image(slide.image)")
        template(v-slot:content)
          .vueperslide__title(v-html="slide.title")
          .vueperslide__content
            .description(v-html="slide.description")
            .languages.layout
              v-icon(color="primary" small) i-magic
              div {{ slide.languages.join(', ') }}
            .link
              a(v-if="slide.url" :href="slide.url" target="_blank")
                v-icon(color="primary" small) i-pointer
                | {{ slide.url.replace(/^https?:\/\/|\/$/g, '') }}
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import aaMeta from '@/components/aa-meta'
import 'vueperslides/dist/vueperslides.css'
import './style.scss'

export default {
  props: ['lang'],
  components: { VueperSlides, VueperSlide },
  data: () => ({
    pageReady: false,
    slideshowBreakpoints: {
      959: {
        slideRatio: 2 / 5
      },
      599: {
        slideRatio: 1 / 2
      }
    }
  }),

  head: function () {
    return aaMeta({
      language: this.lang,
      title: this.texts.pageTitle,
      desc: this.texts.metaDesc,
      keywords: this.texts.metaKey
    })
  },

  methods: {
    image (image) {
      const src = require(`@/assets/images/portfolio/${image}`)
      // eslint-disable-next-line no-prototype-builtins
      return src.hasOwnProperty('default') ? src.default : src
    }
  },

  mounted () {
    setTimeout(() => (this.pageReady = true), 500)
  },

  computed: {
    texts () {
      return require(`./data-${this.lang}.json`)
    }
  },

  watch: {
    lang () {
      this.$nextTick(() => this.$forceUpdate())
    }
  }
}
</script>
