import Vue from 'vue'
import VueRouter from 'vue-router'

import aboutMe from '@/views/about-me/'
import resume from '@/views/resume/'
import portfolio from '@/views/portfolio/'
import contactMe from '@/views/contact-me/'
import download from '@/views/download/'
import appreciate from '@/views/appreciate/'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // English urls.
    {
      path: '/en',
      redirect: { name: 'en-about-me' }
    },
    {
      name: 'en-about-me',
      path: '/en/about-me',
      alias: '/test/test',
      component: aboutMe,
      props: { lang: 'en' }
    },
    {
      name: 'en-resume',
      path: '/en/resume',
      component: resume,
      props: { lang: 'en' }
    },
    {
      name: 'en-portfolio',
      path: '/en/portfolio',
      component: portfolio,
      props: { lang: 'en' }
    },
    {
      name: 'en-contact-me',
      path: '/en/contact-me',
      component: contactMe,
      props: { lang: 'en' }
    },
    {
      name: 'en-download',
      path: '/en/download',
      component: download,
      props: { lang: 'en' }
    },
    {
      name: 'en-appreciate',
      path: '/en/appreciate',
      component: appreciate,
      props: { lang: 'en' }
    },
    // French urls.
    {
      path: '/fr',
      redirect: { name: 'fr-about-me' }
    },
    {
      name: 'fr-about-me',
      path: '/fr/qui-suis-je',
      component: aboutMe,
      props: { lang: 'fr' }
    },
    {
      name: 'fr-resume',
      path: '/fr/curriculum-vitae',
      component: resume,
      props: { lang: 'fr' }
    },
    {
      name: 'fr-portfolio',
      path: '/fr/portfolio',
      component: portfolio,
      props: { lang: 'fr' }
    },
    {
      name: 'fr-contact-me',
      path: '/fr/me-contacter',
      component: contactMe,
      props: { lang: 'fr' }
    },
    {
      name: 'fr-download',
      path: '/fr/telecharger-mon-cv',
      component: download,
      props: { lang: 'fr' }
    },
    {
      name: 'fr-appreciate',
      path: '/fr/apprecier',
      component: appreciate,
      props: { lang: 'fr' }
    },
    {
      path: '*',
      redirect: { name: 'en-about-me' }
    }
  ]
})

export default router
