import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'i',
    values: {
      // Override default Vuetify icons.
      expand: 'i-angle-d'
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00BCD4',
        secondary: '#666',
        maintext: '#999',
        lightgrey: '#f4f4f4'
      }
    }
  }
})
