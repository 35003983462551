<template lang="pug">
v-footer.px-0.py-4
  v-layout.max-widthed(wrap align-center justify-center)
    v-flex.xs12.sm6.text-center.text-sm-left.copyright.
      {{ texts.copyright.replace('{year}', new Date().getFullYear()) }}
    v-flex.xs12.sm6.text-center.text-sm-right.made-with
      | {{ texts.madeWith }} &nbsp;
      v-tooltip(top open-delay="0" content-class="small")
        template(v-slot:activator="{ on }")
          v-icon(v-on="on") i-vuejs
        | Vue JS
      v-tooltip(top open-delay="0" content-class="small")
        template(v-slot:activator="{ on }")
          v-icon(v-on="on") i-html5
        | HTML 5 &amp; Pug
      v-tooltip(top open-delay="0" content-class="small")
        template(v-slot:activator="{ on }")
          v-icon(v-on="on") i-css3
        | CSS 3
      v-tooltip(top open-delay="0" content-class="small")
        template(v-slot:activator="{ on }")
          v-icon(v-on="on") i-sass
        | Sass
      v-tooltip(top open-delay="0" content-class="small")
        template(v-slot:activator="{ on }")
          v-icon(v-on="on") i-magic
        | {{ texts.talent }} ツ
      v-tooltip(top open-delay="0" content-class="small")
        template(v-slot:activator="{ on }")
          v-icon.heart(v-on="on") i-heart
        | {{ texts.love }}
</template>

<script>
import './style.scss'

export default {
  props: ['lang'],
  data: () => ({
    pageReady: false
  }),

  mounted () {
    setTimeout(() => (this.pageReady = true), 500)
  },

  computed: {
    texts () {
      return require(`./data-${this.lang}.json`)
    }
  }
}
</script>
