<template lang="pug">
v-card.page.max-widthed.page--resume(:class="{ ready: pageReady }" flat color="maintext--text")
  v-card-title
    h1
      v-icon(x-large) i-briefcase
      v-slide-x-reverse-transition
        div(v-show="pageReady") {{ texts.pageTitle }}

  v-card-text
    h2.text-center.primary--text.position(v-html="texts.title")
    v-layout.traits(justify-center wrap)
      template(v-for="(trait, i) in traits")
        v-chip(
          :key="trait.label"
          color="lightgrey"
          :class="{ show: trait.visible }"
          small
          @click="trait.visible = false; removeTrait(i)"
          @mouseover="trait.icon = 'i-close'"
          @mouseout="trait.icon = 'i-check'")
          v-icon.mr-2(flat size="1.7em" color="white") {{ trait.icon }}
          | {{ trait.label }}

    .traits__message
      v-slide-y-transition
        v-layout(
          justify-center
          align-center
          v-show="texts.traits.message.show"
          v-html="texts.traits.message.current")

    v-layout.details-wrapper(justify-center)
      v-card(xs2)
        v-card-text.details
          p
            v-icon.mr-2(size="23") i-info-c
            //- span(v-html="texts.iAmCTOForHire")
            span(v-html="texts.iAmCTO")
          p
            v-icon.mr-2 i-pin
            | {{ texts.myLocation }}
          //- p
            v-icon.mr-2 i-phone
            a(:href="'tel:+' + texts.myPhone.replace(/[() +]/g, '')") {{ $options.filters.formatPhone(texts.myPhone, lang) }}
          p
            v-icon.mr-2 i-mail
            span(v-html="texts.myMail")
          p
            v-icon.mr-2(size="23") i-linkedin
            | #[a(:href="`http://${texts.myLinkedin}`" target="_blank") {{ texts.myLinkedin }}]
          p
            v-icon.mr-2(size="22") i-github
            | #[a(:href="`http://${texts.myGithub}`" target="_blank") {{ texts.myGithub }}]
          p
            v-icon.mr-2(size="22") i-codepen
            | #[a(:href="`http://${texts.myCodepen}`" target="_blank") {{ texts.myCodepen }}]
    br
    br
    br

    v-expansion-panels.accordion(popout flat v-model="sections")
      v-expansion-panel.accordion__item.accordion__item--skills(
        :class="{ show: openSection === 'skills' }"
        expand-icon="i-angle-d")
        v-expansion-panel-header.py-0
          v-layout(align-center @click="onSkillTabChange()")
            v-icon.mr-3 i-arrow-r &nbsp;
            h2.pt-1 {{ texts.sections.skills.label }}
        v-expansion-panel-content
          v-tabs(
            v-model="activeTab"
            centered
            :show-arrows="false"
            background-color="transparent"
            color="transparent")
            v-tabs-slider(:color="skillsSectionTabs[activeTab].color")
            v-tab(
              v-for="(tab, i) in skillsSectionTabs"
              :key="i"
              ripple
              @click="onSkillTabChange(tab.id)")
              v-icon.mr-2(:class="tab.icon" :color="tab.color")
              span.tab-item__label.secondary--text &nbsp; {{ tab.label }}

            v-tab-item(v-for="(tab, i) in skillsSectionTabs" :key="i")
              .skills-wrapper(:class="tab.id")
                v-progress-circular.skill(
                  v-for="skill in tab.items"
                  :key="skill.label"
                  :color="tab.color"
                  :value="skill.currentVal"
                  :size="100"
                  :width="10")
                  v-tooltip(bottom delay="0" :disabled="!skill.icon")
                    template(v-slot:activator="{ on }")
                      v-icon(
                        v-if="skill.icon"
                        v-on="on"
                        :class="skill.icon"
                        :color="tab.color"
                        :size="skill.size || 40")
                      strong(v-else v-html="skill.label")
                    | {{ skill.label }}

      v-expansion-panel.accordion__item.accordion__item--experience(
        :class="{ show: openSection === 'experience' }"
        expand-icon="i-angle-d")
        v-expansion-panel-header.py-0
          v-layout(align-center)
            v-icon.mr-3 i-arrow-r &nbsp;
            h2.pt-1 {{ texts.sections.experience.label }}
        v-expansion-panel-content
          .timeline
            .timeline__bg
              .timeline__bg--top
              .timeline__bg--bottom
            .timeline-item(
              :class="{ ongoing: item.ongoing && getOngoingJobDuration(item), 'timeline-item--short': item.duration > -1 && item.duration <= 3 }"
              v-for="(item, i) in texts.sections.experience.items"
              :key="i")
              .timeline-item__bg(:style="'height: ' + (10 * item.duration) + 'px'")
              .timeline-item__name-wrapper
                span.timeline-item__name(:class="{ 'pr-0 pb-2': item.events, 'no-place': !item.place }")
                  span(v-html="item.name")
                  v-icon.timeline-item__location-pin(v-if="item.at || item.place" color="primary" size="22") i-pin
                  .timeline-item__location
                    span.primary--text(v-html="item.at")
                    em(v-if="item.place") {{ item.place }}
                    img.mt-3.mb-2.timeline-item__logo(
                      v-if="item.logo"
                      :src="getImage(item.logo)"
                      :style="item.logoWidth ? `width: ${item.logoWidth}` : ''"
                      alt="")
                ul.timeline-item__events(v-if="item.events")
                  li.timeline-sub-item__name-wrapper(v-for="(subItem, j) in item.events")
                    span.timeline-sub-item__name
                      span(v-html="subItem.name")
                      v-icon.timeline-item__location-pin(v-if="subItem.at || subItem.place" color="primary" size="18") i-pin
                      .timeline-item__location
                        span.primary--text(v-html="subItem.at")
                        em(v-if="subItem.place") {{ subItem.place }}
                        img.mt-3.mb-2.timeline-item__logo(
                          v-if="subItem.logo"
                          :src="getImage(subItem.logo)"
                          :style="subItem.logoWidth ? `width: ${subItem.logoWidth}` : ''" alt="")
                span.timeline-item__date(:class="{ ongoing: item.ongoing }")
                  | {{ parseInt(item.date) }}
                  template(v-if="item.ongoing")=" - {{ texts.now }}"
                span.timeline-item__duration(v-if="item.duration > 2") {{ item.duration | formatDuration }}

      v-expansion-panel.accordion__item.accordion__item--education(
        :class="{ show: openSection === 'education' }"
        expand-icon="i-angle-d")
        v-expansion-panel-header.py-0
          v-layout(align-center)
            v-icon.mr-3 i-arrow-r &nbsp;
            h2.pt-1 {{ texts.sections.education.label }}
        v-expansion-panel-content
          .timeline
            .timeline__bg
              .timeline__bg--top
              .timeline__bg--bottom
            .timeline-item(
              :class="{ 'timeline-item--short': item.duration <= 3 }"
              v-for="(item, i) in texts.sections.education.items"
              :key="i")
              .timeline-item__bg(:style="'height: ' + (10 * item.duration) + 'px'")
              .timeline-item__name-wrapper
                span.timeline-item__name
                  span(v-html="item.name")
                  v-icon.timeline-item__location-pin(color="primary" size="22") i-pin
                  .timeline-item__location
                    span.primary--text {{ item.at }}
                    em(v-if="item.place") {{ item.place }}
                    img.logo(v-if="item.logo" :src="getImage(item.logo)" :style="item.logoWidth ? `width: ${item.logoWidth}` : ''" alt="")
                span.timeline-item__date {{ parseInt(item.date) }}
                span.timeline-item__duration(v-if="item.duration > 3") {{ item.duration | formatDuration }}

      v-expansion-panel.accordion__item.accordion__item--languages(
        :class="{ show: openSection === 'languages' }"
        expand-icon="i-angle-d")
        v-expansion-panel-header.py-0
          v-layout(align-center)
            v-icon.mr-3 i-arrow-r &nbsp;
            h2.pt-1 {{ texts.sections.languages.label }}
        v-expansion-panel-content
          v-layout.languages-wrapper(justify-space-around wrap)
            .language(v-for="language in texts.sections.languages.items")
              span.primary--text.label {{ language.label }}
              span.desc
                span.level(:style="{ width: language.level + '%' }")
                span.text {{ language.description }}

      v-expansion-panel.accordion__item.accordion__item--sports(
        :class="{ show: openSection === 'sports' }"
        expand-icon="i-angle-d")
        v-expansion-panel-header.py-0
          v-layout(align-center)
            v-icon.mr-3 i-arrow-r &nbsp;
            h2.pt-1 {{ texts.sections.sports.label }}
        v-expansion-panel-content
          v-layout.sports-wrapper(justify-center wrap)
            .sport(v-for="sport in texts.sections.sports.items" :id="sport.id")
              svg(
                :class="'svg-inline--fa ' + sport.id"
                v-if="sport.svg"
                ns="http://www.w3.org/2000/svg"
                fill="currentColor"
                color="#ccc"
                viewBox="0 0 512 512")
                path(:d="sport.svg")
              v-icon(v-else x-large) {{ sport.id }}
              .description
                h3.title {{ sport.title }}
                p.maintext--text(v-html="sport.description")

//- v-dialog(v-model="dialog" max-width="500px")
  v-card
    v-card-text This is me skating!
</template>

<script>
import aaMeta from '@/components/aa-meta'
import './style.scss'

let dateFormat = {}

export default {
  props: ['lang'],
  data: () => ({
    pageReady: false,
    activeTab: 0, // Default active tab in skills section.
    openSection: '', // Accordion panel.
    texts: { traits: { items: [] }, sections: { skills: { items: [] } } }
  }),

  head () {
    return aaMeta({
      language: this.lang,
      title: this.texts.pageTitle,
      desc: this.texts.metaDesc,
      keywords: this.texts.metaKey
    })
  },

  created () {
    this.texts = require(`./data-${this.lang}.json`)
    dateFormat = this.texts.dateFormat
  },

  mounted () {
    setTimeout(() => (this.pageReady = true), 500)
  },

  methods: {
    onSkillTabChange (id) {
      this.skillsSectionTabs[this.activeTab].items.forEach(skill => (skill.currentVal = 0))
      setTimeout(() => {
        this.skillsSectionTabs[this.activeTab].items.forEach(skill => {
          skill.currentVal = this.openSection === 'skills' ? skill.value : 0
        })
      }, 700)
    },
    removeTrait (i) {
      this.texts.traits.message.current = this.texts.traits.message.some
      this.texts.traits.message.show = true

      const someTraitsVisible = this.texts.traits.items.some(trait => trait.visible)

      // If all the traits are removed re-append them 1 by 1.
      if (!someTraitsVisible) {
        setTimeout(() => {
          this.texts.traits.message.show = false
          this.traits.forEach(trait => (trait.visible = true))

          setTimeout(() => {
            this.texts.traits.message.current = this.texts.traits.message.all
            this.texts.traits.message.show = true

            setTimeout(() => (this.texts.traits.message.show = false), 2000)
          }, 1800)
        }, 500)
      }
    },
    getImage: src => {
      src = require('@/assets/images/resume/' + src)
      // eslint-disable-next-line no-prototype-builtins
      return src.hasOwnProperty('default') ? src.default : src
    },
    getOngoingJobDuration (job) {
      const today = new Date()
      const y = today.getFullYear()
      const m = today.getMonth() + 1
      const [startYear, startMonth] = job.date.split('.')
      job.duration = ((y - parseInt(startYear)) * 12) + (m - parseInt(startMonth))

      return true
    }
  },

  computed: {
    traits () {
      return this.texts.traits.items.map(item => {
        this.$set(item, 'visible', true)
        this.$set(item, 'icon', 'i-check')
        return item
      })
    },
    sections: {
      get () {
        return Object.entries(this.texts.sections).map(([id, item]) => {
          this.$set(item, 'id', id)
          return item
        })
      },
      set (value) {
        setTimeout(() => (this.openSection = (this.sections[value] || {}).id), 200)
      }
    },
    skillsSectionTabs () {
      const { skills: { items: tabs = {} } = {} } = this.texts.sections
      return Object.values(tabs).map(tab => {
        tab.items.map(skill => {
          this.$set(skill, 'currentVal', 0)
          return skill
        })
        return tab
      })
    }
  },

  watch: {
    lang () {
      this.texts = require(`./data-${this.lang}.json`)
      dateFormat = this.texts.dateFormat
    }
  },

  filters: {
    formatPhone: (phone, lang) => lang === 'en' ? phone.replace(/\(.*\) /g, '0') : phone,
    formatDuration: months => {
      if (!months) return ''

      let years = Math.floor(months / 12)

      if (months <= 1) months = `${months} ${dateFormat.month}`
      else if (months % 12) months = `${months % 12} ${dateFormat.months}`
      else months = ''

      if (years > 1) years = `${years} ${dateFormat.year}s`
      else if (years === 1) years = `1 ${dateFormat.year}`
      else years = ''

      return years && months ? `${years}, ${months}` : `${years} ${months}`
    }
  }
}
</script>
