import Vue from 'vue'
import App from './app'
import vuetify from './plugins/vuetify'
import VueHead from 'vue-head'
import router from './router'

Vue.config.productionTip = false

// Meta data.
Vue.use(VueHead)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
