<template lang="pug">
v-app(light v-scroll="onScroll" :lang="lang")
  v-container(fluid)
    aa-toolbar(:toolbar-class="{ scrolled: offsetTop }" :lang="lang")
    router-view
  aa-footer(:lang="lang")
</template>

<script>
import aaToolbar from '@/components/aa-toolbar'
import aaFooter from '@/components/aa-footer'

export default {
  components: { aaToolbar, aaFooter },

  data: () => ({
    offsetTop: 0,
    lang: 'en'
  }),

  methods: {
    onScroll (e) {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    }
  },

  created () {
    // First set the language from URL on page load.
    this.lang = this.$router.currentRoute.name.substr(0, 2)
  },

  watch: {
    // On route change, check if language has changed,
    // if so update it and pass it to the toolbar & footer components.
    $route (to, from) {
      const toLang = to.name.substr(0, 2)

      if (toLang !== from.name.substr(0, 2)) this.lang = toLang
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/index.scss';
</style>
