<template lang="pug">
v-toolbar.v-toolbar--fixed(
  height="38"
  flat
  tag="nav"
  :class="toolbarClass")
  .v-toolbar__items.flex.main-menu
      v-btn.pa-0(depressed :to="{ name: `${lang}-about-me` }")
        v-icon(size="2.3em") i-user-o
        span.label {{ texts.aboutMe }}
      v-btn.pa-0(depressed :to="{ name: `${lang}-resume` }")
        v-icon i-briefcase-o
        span.label.resume {{ texts.resume }}
      v-btn.pa-0(depressed :to="{ name: `${lang}-portfolio` }")
        v-icon i-apps
        span.label {{ texts.portfolio }}
      v-btn.pa-0(depressed :to="{ name: `${lang}-contact-me` }")
        v-icon i-mail-o
        span.label {{ texts.contact }}
      v-btn.pa-0(depressed :to="{ name: `${lang}-download` }")
        v-icon i-attach
        span.label.download {{ texts.download }}
      v-btn.pa-0(depressed :to="{ name: `${lang}-appreciate` }")
        v-icon i-thumb-up-o
        span.label {{ texts.appreciate }}
  .language-selector
    v-btn(:color="lang === 'en' ? 'primary' : 'maintext'" icon outlined small @click="changeLanguage('en')")
      span EN
    v-btn(:color="lang === 'fr' ? 'primary' : 'maintext'" icon outlined small @click="changeLanguage('fr')")
      span FR
</template>

<script>
import './style.scss'

export default {
  props: ['lang', 'toolbarClass'],
  data: () => ({
    pageReady: false
  }),

  methods: {
    changeLanguage (lang) {
      this.$router.push({
        name: this.$router.currentRoute.name.replace(/^(fr|en)-/, `${lang}-`),
        params: { lang }
      })
    }
  },

  mounted () {
    setTimeout(() => (this.pageReady = true), 500)
  },

  computed: {
    texts () {
      return require(`./data-${this.lang}.json`)
    }
  }
}
</script>
