<template lang="pug">
v-card.page.max-widthed.page--appreciate(:class="{ ready: pageReady }" flat color="maintext--text")
  v-card-title
    h1
      i(role="button" tabindex="0" class="v-icon i-thumbs-up" :class="{ liked: hasLiked }" @click="like" style="font-size: 40px")
      v-slide-x-reverse-transition
        div(v-show="pageReady") {{ texts.pageTitle }}

  v-card-text.appreciate__wrapper(:class="{ 'liked': hasLiked }")
    div.text-center.will-you
      h2 {{ texts.willYou }}
    div.text-center.thank-you
      h2.primary--text {{ texts.thankYou }}
      p {{ texts.iAppreciate }}
        em {{ texts.betweenYouAndMe }}
      hr
      p
        span#thumbsup {{ likes }}
        | &nbsp;{{ texts.personsAppreciated }}
</template>

<script>
import axios from 'axios'
import aaMeta from '@/components/aa-meta'
import './style.scss'

export default {
  props: ['lang'],
  data: () => ({
    pageReady: false,
    hasLiked: false,
    likes: 0,
    loading: false
  }),

  head () {
    return aaMeta({
      language: this.lang,
      title: this.texts.pageTitle,
      desc: this.texts.metaDesc,
      keywords: this.texts.metaKey
    })
  },

  methods: {
    like () {
      this.hasLiked = !this.hasLiked
      this.loading = true

      axios.post('/api/like', { like: this.hasLiked ? 'y' : 'n' })
        .then(response => {
          this.loading = false
          this.likes = response.data.likes
          this.hasLiked = response.data.liked
        }, () => {
          this.loading = false
        })
    },
    getLikes () {
      this.loading = true
      axios.get('/api/get-likes')
        .then(response => {
          this.loading = false
          this.likes = response.data.likes
          this.hasLiked = response.data.liked
        }, () => {
          this.loading = false
        })
    }
  },

  mounted () {
    this.getLikes()

    setTimeout(() => (this.pageReady = true), 500)
  },

  computed: {
    texts () {
      return require(`./data-${this.lang}.json`)
    }
  }
}
</script>
