<template lang="pug">
v-card.page.max-widthed.page--download(:class="{ ready: pageReady }" flat color="maintext--text")
  v-card-title
    h1
      v-icon(x-large) i-attach
      v-slide-x-reverse-transition
        div(v-show="pageReady") {{ texts.pageTitle }}

  v-card-text
    p.intro {{ texts.downloadBelow }}
    .pdf-wrapper
      object(
        :data="pdfUrl"
        type="application/pdf"
        width="100%"
        height="100%")
        iframe(:src="pdfUrl" width="100%" height="100%")
          p {{ texts.notSupported }} #[a(:href="pdfUrl") {{ texts.here }}]
</template>

<script>
import aaMeta from '@/components/aa-meta'
import pdfUrl from '@/assets/antoni-andre--resume-2024.pdf'
import './style.scss'

export default {
  props: ['lang'],
  data: () => ({
    pageReady: false,
    pdfUrl: pdfUrl + '#view=Fit&pagemode=none&navpanes=0'
  }),

  head () {
    return aaMeta({
      language: this.lang,
      title: this.texts.pageTitle,
      desc: this.texts.metaDesc,
      keywords: this.texts.metaKey
    })
  },

  mounted () {
    setTimeout(() => (this.pageReady = true), 500)
  },

  computed: {
    texts () {
      return require(`./data-${this.lang}.json`)
    }
  }
}
</script>
