/**
 * Inject meta tags into document head with vue-head package.
 */

const languages = { en: 'en_US', fr: 'fr_FR' }

export default (data) => ({
  title: { inner: data.title },
  meta: [
    { 'http-equiv': 'Content-language', content: data.language },
    { name: 'author', content: 'Antoni André' },
    { property: 'og:author', content: 'Antoni André' },
    { property: 'og:locale', content: languages[data.language] },
    { property: 'og:site_name', content: 'Antoni André, CTO' },
    { property: 'og:url', content: 'http://antoniandre.me/' + data.language },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: data.title },
    { property: 'og:description', content: data.desc },
    { itemprop: 'name', content: data.title },
    { itemprop: 'description', content: data.desc },
    { name: 'description', content: data.desc },
    { name: 'keywords', content: data.keywords || 'Antoni André, CTO, ' + data.title }
  ]
})
