<template lang="pug">
v-card.page.max-widthed.page--about-me(:class="{'ready': pageReady}" flat color="maintext--text")
  v-card-title
    //- .forHire(v-html="texts.forHire")
    h1
      v-icon.icon--user i-user-o
      v-slide-x-reverse-transition
        div(v-show="pageReady") {{ texts.pageTitle }}

  v-card-text
    .block.block--first
      h2(v-html="texts.iAmAntoni")
      p(v-html="texts.iAmCTO")
      v-expand-transition
        .more(v-show="showMore1" v-html="texts.introText")
      .text-center.hidden-sm-and-up
        span.d-inline-block.read-more(@click="showMore1 = !showMore1")
          span.d-block.grey--text.text--lighten-1 {{ showMore1 ? texts.less : texts.more }}
          v-icon(color="grey lighten-1") {{ showMore1 ? 'i-angle-u' : 'i-angle-d' }}

    .block.block--second
      h2(v-html="texts.whatIDo")
      p(v-html="texts.iLove")
      v-expand-transition
        .more(v-show="showMore2" v-html="texts.iHaveManyProjects")
      .text-center.hidden-sm-and-up
        span.d-inline-block.read-more(@click="showMore2 = !showMore2")
          span.d-block.grey--text.text--lighten-1 {{ showMore2 ? texts.less : texts.more }}
          v-icon(color="grey lighten-1") {{ showMore2 ? 'i-angle-u' : 'i-angle-d' }}

    .block.block--third
      h2(v-html="texts.checkOutRecent")
      v-layout.project(align-center)
        div
          svg(viewBox="0 0 1000 1000")
            path(d="M469 500v490h57V10h-57v490z")
            path(d="m109 417-83 83 80 80 81 81 20-20 20-20-46-46-46-46h219v-58H135l48-47 49-49c0-2-9-11-20-22l-20-20-83 84z")
            path(d="m789 354-21 20 11 11 46 48 35 37-109 1H641v58h219l-46 46-46 46 21 20 20 20 82-83 83-83-13-11-82-81-70-70-20 21z")
        .pl-3
          h3
            a(href="https://antoniandre.github.io/splitpanes" target="_blank") Splitpanes
            | &nbsp;- {{ texts.on }}&nbsp;
            a.project__link(href="https://www.npmjs.com/package/splitpanes" target="_blank")
              span NPM
              img.ml-1(src="https://img.shields.io/npm/dt/splitpanes.svg" alt="Splitpanes total downloads" height="18")
              img.ml-1(src="https://img.shields.io/npm/dw/splitpanes.svg" alt="Splitpanes weekly downloads" height="18")
          p(v-html="texts.project1desc")

      v-layout.project.project--1(align-center)
        div
          svg(viewBox="0 0 496 496")
            path(d="m488 40h-48v-32c0-4.417969-3.582031-8-8-8h-40c-4.417969 0-8 3.582031-8 8v32h-272v-32c0-4.417969-3.582031-8-8-8h-40c-4.417969 0-8 3.582031-8 8v32h-48c-4.417969 0-8 3.582031-8 8v440c0 4.417969 3.582031 8 8 8h480c4.417969 0 8-3.582031 8-8v-440c0-4.417969-3.582031-8-8-8zm-248 120v96h-104v-96zm120 0v96h-104v-96zm120 0v96h-104v-96zm-240 112v96h-104v-96zm0 112v96h-104v-96zm16 0h104v96h-104zm0-16v-96h104v96zm120-96h104v96h-104zm24-256h24v64h-24zm-328 0h24v64h-24zm-56 40h40v32c0 4.417969 3.582031 8 8 8h40c4.417969 0 8-3.582031 8-8v-32h272v32c0 4.417969 3.582031 8 8 8h40c4.417969 0 8-3.582031 8-8v-32h40v88h-464zm0 104h104v96h-104zm104 112v96h-104v-96zm-104 112h104v96h-104zm360 96v-96h104v96zm0 0")
        .pl-3
          h3
            a(href="https://antoniandre.github.io/vue-cal" target="_blank") Vue Cal
            | &nbsp;- {{ texts.on }}&nbsp;
            a.project__link(href="https://www.npmjs.com/package/vue-cal" target="_blank")
              span NPM
              img.ml-1(src="https://img.shields.io/npm/dt/vue-cal.svg" alt="vue-cal total downloads" height="18")
              img.ml-1(src="https://img.shields.io/npm/dw/vue-cal.svg" alt="vue-cal weekly downloads" height="18")
          p(v-html="texts.project2desc")

      //- v-layout.project(align-center)
        div
          svg(viewBox="0 0 442 442")
            path(d="M166.6 225.2c4 16 27.3 26.3 59.5 26.3 24 0 39.3-8.8 45.6-17a19.7 19.7 0 0 0 3.5-18c-3.6-13-22.4-19.3-52.8-28.2l-8.5-2.5c-31-9-66.3-19.3-73.7-46.2a43.3 43.3 0 0 1 8.5-39.8 10 10 0 0 0-7.8-16.4h-35a10 10 0 0 0-7.7 3.5l-43 50a10 10 0 0 0 0 13l74.3 86.4a10 10 0 0 0 17.5-7.7 10 10 0 0 1 19.6-3.4zm-16.9-26.7c-6.9 1.7-12.7 5.7-16.8 11.1l-57-66.2 34.5-40h13a62.7 62.7 0 0 0-2.5 41.6c10.5 37.6 53.1 50 87.4 60l8.5 2.5c8.5 2.5 18.1 5.3 26 8.3a45.3 45.3 0 0 1 13 6.5c-2.8 3.7-13 9.2-29.7 9.2-27.2 0-39-8.3-40-11a30 30 0 0 0-36.4-22zM263.2 264.5a97 97 0 0 1-37.1 7c-18.8 0-35.8-3.2-49.1-9.3a10 10 0 0 0-11.8 15.6l48.2 56a10 10 0 0 0 15.2 0l46-53.5a10 10 0 0 0-11.4-15.8zM221 312l-19-22a167.4 167.4 0 0 0 37.2 1l-18.2 21z")
            path(d="M336.2 83.4h-36.6a10 10 0 0 0-7.5 16.6 44.8 44.8 0 0 1 11.4 30.3 10 10 0 0 1-20 0c0-25.3-34.2-36.6-68.1-36.6-27.3 0-44.7 10-51.8 19.5a23 23 0 0 0-4.1 21c4.1 15 27.5 23 60 32.4l8.5 2.5c30.6 9 59.6 17.4 66.4 42 1.6 5.6 2 11.1 1.2 16.6a10 10 0 0 0 17.5 7.9l73.7-85.6a10 10 0 0 0 0-13l-43-50a10 10 0 0 0-7.6-3.6zm-22.8 121.2c-10.3-34.5-47.2-45.2-79.8-54.7l-8.5-2.5c-10-2.9-21.2-6.2-30.7-10-12.5-4.9-15.3-8.1-15.7-8.7-.4-1.4-.3-2 .8-3.4 3-4 14.4-11.6 35.9-11.6 27.5 0 48.1 8.8 48.1 16.6a30 30 0 0 0 60 0c0-9.5-2-18.5-5.7-26.9h13.8l34.4 40-52.6 61.2z")
            path(d="M439.6 137L362 47a10 10 0 0 0-7.6-3.6h-267a10 10 0 0 0-7.6 3.5L2.4 137a10 10 0 0 0 0 13l211 245.2a10 10 0 0 0 15.2 0l211-245.1a10 10 0 0 0 0-13zM221 373.1L23.2 143.4l68.9-80h257.8l69 80L221 373.2z")
        .pl-3
          h3
            a(href="https://antoniandre.github.io/vueper-slides" target="_blank") Vueper Slides
            | &nbsp;- {{ texts.on }}&nbsp;
            a.project__link(href="https://www.npmjs.com/package/vueperslides" target="_blank")
              span NPM
              img.ml-1(src="https://img.shields.io/npm/dt/vueperslides.svg" alt="vueperslides total downloads" height="18")
              img.ml-1(src="https://img.shields.io/npm/dw/vueperslides.svg" alt="vueperslides weekly downloads" height="18")
          p(v-html="texts.project2desc")

      v-layout.project(align-center)
        v-icon i-shaker
        .pl-3
          h3
            a(:href="`https://sweetcocktails.com/${lang}/`" target="_blank") sweetcocktails.com
          p {{ texts.project3desc }}

      h3.text-center
        router-link.see-all-projects(:to="{ name: lang + '-portfolio' }")
          v-icon(color="primary") i-angle-r
          | &nbsp; {{ texts.seeAll }}
</template>

<script>
import aaMeta from '@/components/aa-meta'
import './style.scss'

export default {
  props: ['lang'],
  data: () => ({
    pageReady: false,
    showMore1: false,
    showMore2: false
  }),

  head: function () {
    return aaMeta({
      language: this.lang,
      title: this.texts.pageTitle,
      desc: this.texts.metaDesc,
      keywords: this.texts.metaKey
    })
  },

  mounted () {
    setTimeout(() => (this.pageReady = true), 500)
  },

  computed: {
    texts () {
      return require(`./data-${this.lang}.json`)
    }
  }
}
</script>
