<template lang="pug">
v-card.page.max-widthed.page--contact-me(flat color="maintext--text")
  v-card-title
    h1
      v-icon(x-large) i-mail-o
      v-slide-x-reverse-transition
        div(v-show="pageReady") {{ texts.pageTitle }}

  v-card-text
    div.gmap.loaded(:class="{ show: gmapLoaded }")
      div.where-i-live {{ texts.whereILive }}
      div#map-canvas

      v-card.my-details(:class="{ show: showDetails }")
        v-card-title
          div.flex.primary--text(v-html="texts.me")
          v-icon(@click="showDetails = false") i-close
        v-card-text
          p
            v-icon i-pin
            | {{ texts.myAddress }}
          //- p
            v-icon i-phone
            | {{ texts.myPhone }}
          p
            v-icon i-mail
            span(v-html="texts.myEmail")
          p
            v-icon i-linkedin
            a(:href="texts.linkedInUrl" target="_blank") {{ texts.linkedInTitle }}

    div.contact-wrapper
      div.contact__message.contact__message--success(:class="{ show: showMessage }" v-if="sent")
        svg.success--text(viewBox="0 0 37 37" stroke="currentColor" fill="none")
          path.circ.path(d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z")
          polyline.tick.path(points="11.6,20 15.9,24.2 26.4,13.8")
        h2.success--text {{ texts.thankYou }}
        p {{ texts.messageOTW }}
        v-btn.grey--text(v-if="showMessage" color="white" absolute top right icon @click="sent = null;showMessage = false")
          v-icon i-close

      div.contact__message.contact__message--failure(:class="{ show: showMessage }" v-if="validForm && sent === false")
        v-icon(size="60" color="grey lighten-2") i-frown-o
        h2.error--text {{ texts.aww }}
        p {{ texts.messageFailed }}
        v-btn.grey--text(v-if="showMessage" color="white" absolute top right icon @click="sent = null;showMessage = false")
          v-icon i-close

      h2 {{ texts.aQuestion }}
      v-form(ref="form" v-model="validForm" lazy-validation)
        v-layout
          v-text-field.xs6.contact__field--firstName(v-model="firstName" :rules="firstNameRules" :label="texts.firstName" prepend-icon="i-user-tie" required)
          v-text-field.xs6.contact__field--lastName(v-model="lastName" :rules="lastNameRules" :label="texts.lastName" prepend-icon="i-user-tie" required)
        v-text-field.contact__field--email(v-model="email" :rules="emailRules" type="email" :label="texts.email" prepend-icon="i-at" required)
        v-textarea.contact__field--message(v-model="message" :rules="messageRules" :label="texts.message" prepend-icon="i-comment" required auto-grow rows="1")
        v-layout(align-center)
          v-spacer
          v-layout.robot-check.mr-2(:class="{ passed: robotCheckPassed }" align-center)
            div.d-inline-flex.align-center.robot-check__question
              | {{ texts.areYouRobot }} &nbsp;
              v-switch.d-inline-flex.ml-1(:input-value="!robotCheckPassed" color="error" @click.native="robotCheckPassed = !robotCheckPassed" hide-details)
              span.robot-check__answer {{ robotCheckPassed ? this.texts.no : this.texts.yes }}
            v-icon(:color="robotColor" large) i-android
          v-btn.white--text.mr-0(small :color="button.color" :disabled="!validForm || sent" @click="send")
            v-icon.mr-1 {{ button.icon }}
            | {{ button.label }}
</template>

<script>
import axios from 'axios'
import aaMeta from '@/components/aa-meta'
// This Google Maps project is created with disadb account and named cogent-bison.
import { Loader } from 'google-maps'
import mapMarkerImage from '@/assets/images/map-marker.png'
import './style.scss'

export default {
  props: ['lang'],
  data: function () {
    return {
      pageReady: false,
      texts: {},
      firstName: '',
      firstNameRules: [v => !!v || this.texts.required],
      lastName: '',
      lastNameRules: [v => !!v || this.texts.required],
      email: '',
      emailRules: [
        v => !!v || this.texts.required,
        v => /^[a-z0-9_.-]+@[a-z0-9_.-]+$/i.test(v) || this.texts.validEmail
      ],
      message: '',
      messageRules: [v => !!v || this.texts.required],
      robotCheckPassed: null,
      sending: false,
      validForm: true,
      sent: null,
      showDetails: false,
      showMessage: false,
      gmapLoaded: false,
      map: null,
      marker1: null
    }
  },

  head: function () {
    return aaMeta({
      language: this.lang,
      title: this.texts.pageTitle,
      desc: this.texts.metaDesc,
      keywords: this.texts.metaKey
    })
  },

  methods: {
    send () {
      this.sent = null

      if (this.robotCheckPassed === null) this.robotCheckPassed = false

      if (this.$refs.form.validate() && this.robotCheckPassed) {
        this.sending = true
        axios.post('/api/contact', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          message: this.message,
          robotCheck: this.robotCheckPassed ? 'passed' : false
        })
          .then((response) => {
            this.sending = false
            this.sent = !response.error

            if (this.sent) this.$refs.form.reset()

            setTimeout(() => (this.showMessage = true), 200)
          }, () => {
            this.sending = false
            this.sent = false
            setTimeout(() => (this.showMessage = true), 200)
          })
      }
    },
    initGmap () {
      // Google API key.
      const loader = new Loader('AIzaSyCCFkjtOLy74oaxDxjyzIpE3h5RIee7FJQ')

      loader.load().then(google => {
        // Set the map params.
        this.map = new google.maps.Map(document.getElementById('map-canvas'), {
          zoom: this.texts.zoom,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          center: new google.maps.LatLng(...this.texts.center.split(','))
        })
        this.gmapLoaded = true

        // Set map custom style.
        const customMapStyle = new google.maps.StyledMapType([
          { featureType: 'all', stylers: [{ hue: '#00BCD4' }, { saturation: -7 }] },
          { featureType: 'poi', stylers: [{ visibility: 'off' }] }
        ])

        this.map.mapTypes.set('myMapStyle', customMapStyle)
        this.map.setMapTypeId('myMapStyle')

        // Set a marker.
        this.marker1 = new google.maps.Marker({
          icon: new google.maps.MarkerImage(mapMarkerImage, new google.maps.Size(24, 36)),
          map: null,
          animation: google.maps.Animation.DROP,
          position: new google.maps.LatLng(...this.texts.marker.split(','))
        })

        setTimeout(() => this.marker1.setMap(this.map), 2000)

        // Click on marker.
        this.marker1.addListener('click', () => (this.showDetails = true))
      })
    },
    recenterGmap () {
      // eslint-disable-next-line
      const center = new google.maps.LatLng(...this.texts.center.split(','))
      // eslint-disable-next-line
      const markerPosition = new google.maps.LatLng(...this.texts.marker.split(','))

      this.map.setCenter(center)
      this.map.setZoom(this.texts.zoom)
      this.map.panTo(center)

      this.marker1.setPosition(markerPosition)
    }
  },

  created () {
    this.texts = require(`./data-${this.lang}.json`)
  },

  mounted () {
    setTimeout(() => (this.pageReady = true), 500)

    this.initGmap()
  },

  computed: {
    robotColor () {
      return this.robotCheckPassed || this.robotCheckPassed === null ? 'primary' : 'error'
    },
    button () {
      const button = {}
      button.icon = 'i-check'
      button.label = this.texts.send
      button.color = 'primary'

      switch (true) {
        case (this.sending):
          button.icon = 'i-sync'
          button.label = this.texts.sending
          button.color = 'cyan lighten-1'
          break
        case (this.sent):
          button.icon = 'i-check'
          button.label = this.texts.sent
          button.color = 'lime accent-4'
          break
        case (this.sent === false):
          button.icon = 'i-close'
          button.label = this.texts.notSent
          button.color = 'error'
          break
      }
      return button
    }
  },

  watch: {
    lang () {
      this.texts = require(`./data-${this.lang}.json`)

      this.recenterGmap()
    }
  }
}
</script>
